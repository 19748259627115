<template>
    <b-sidebar
            id="search-and-filter-sidebar"
            :visible="isActive"
            bg-variant="white"
            sidebar-class="sidebar-lg"
            shadow
            backdrop
            no-header
            right
            @change="(val) => $emit('update:is-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    جزییات تراکنش کاربر {{order.fname}}
                </h5>

                <feather-icon
                        class="ml-1 cursor-pointer"
                        icon="XIcon"
                        size="16"
                        @click="hide"
                />

            </div>

            <div class="d-flex flex-column px-2 py-1">
                <p>
                    شماره تراکنش : {{order.id}}
                </p>
                <p>
                    مقدار تراکنش : {{$toLocal(order.amount,$decimal[order.coin])}} {{$coins[order.coin].persianName}}
                </p>
                <p>
                    حساب مبدا/مقصد : {{order.destinationWalletAddress}}
                </p>
                <p v-if="order.createdAtDateTime">
                    تاریخ بررسی : {{$G2J(order.createdAtDateTime)}}
                </p>
                <p :class="`text-${statusVariant(order.withdrawalRequestStatus)}`">
                    وضعیت : {{statusLabel(order.withdrawalRequestStatus)}}
                </p>
                <p>
                    شماره پیگیری / هش کد :
                    <a :href="order.hashCode" @click.prevent="" target="_blank">{{order.hashCode}}</a>
                </p>
            </div>

        </template>
    </b-sidebar>
</template>

<script>
    import {
        BSidebar,
    } from 'bootstrap-vue'

    export default {
        name: 'historyDetails',
        components: {
            BSidebar,
        },
        model: {
            prop: 'isActive',
            event: 'update:is-active',
        },
        props: {
            isActive: {
                type: Boolean,
                required: true,
            },
            order: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                userData: [],
                select1Value: [],
                select2Value: [],
                cleave1Value: [],
                cleave2Value: [],
                keyValue: 0,
                date: '',
                number: '',
                selected: '',
            }
        },
        computed:{
            statusVariant() {
                const a = {
                    CANCELLED_BY_ADMIN : 'danger',
                    ACCEPTED : 'success',
                    RAISED_BY_USER : 'info',
                    UNCONFIRMED : 'warning',
                    CONFIRMED : 'success',
                }
                return e => a[e];
            },
            statusLabel() {
                const a = {
                    CANCELLED_BY_ADMIN : 'لغو شده توسط مدیر',
                    ACCEPTED : 'انجام شده',
                    RAISED_BY_USER : 'انجام نشده',
                    UNCONFIRMED : 'تایید نشده',
                    CONFIRMED : 'تایید شده',
                }
                return e => a[e];
            },
        },
        methods: {

        }
    }
</script>

<style lang="scss">
    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    .input-group-prepend {
        .v-select {
            .vs__dropdown-toggle {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
</style>


